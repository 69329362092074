import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "vant/es/list/style";
import _List from "vant/es/list";
import "vant/es/empty/style";
import _Empty from "vant/es/empty";
import "vant/es/button/style";
import _Button from "vant/es/button";
import ActiveRateChart from '@/components/ActiveRateChart.vue';
import { attRateDetail, attRateDetailSummary } from '@/api/wxwork';
import dayjs from 'dayjs';
export default {
  components: {
    Button: _Button,
    ActiveRateChart,
    Empty: _Empty,
    List: _List,
    Cell: _Cell
  },
  data() {
    return {
      week: 1,
      errorType: 1,
      listData1: [],
      listData2: [],
      chartData1: [],
      chartData2: []
    };
  },
  mounted() {
    document.title = '活跃度详情';
    this.week = this.$route.query.week;
    this.getData(this.errorType);
    this.getChartData();
  },
  methods: {
    getData() {
      attRateDetail({
        errorType: 1,
        week: this.week
      }).then(res => {
        if (!res.data.data || !Array.isArray(res.data.data)) return;
        res.data.data.map(item => {
          item.updateDate = item.updateDate ? dayjs(item.updateDate).format('YYYY-MM-DD') : '';
        });
        this.listData1 = res.data.data;
      });
      attRateDetail({
        errorType: 2,
        week: this.week
      }).then(res => {
        if (!res.data.data || !Array.isArray(res.data.data)) return;
        res.data.data.map(item => {
          item.updateDate = item.updateDate ? dayjs(item.updateDate).format('YYYY-MM-DD') : '';
        });
        this.listData2 = res.data.data;
      });
    },
    getChartData() {
      attRateDetailSummary({
        week: this.week
      }).then(res => {
        if (!res.data.data) return;
        let data = res.data.data && res.data.data.length ? res.data.data[0] : {};
        this.chartData1 = [{
          const: 'const',
          type: '正常打卡',
          cnt: this.formatNum(data.normalPositionRate)
        }, {
          const: 'const',
          type: '位置异常',
          cnt: this.formatNum(data.abnormalPositionRate)
        }];
        this.chartData2 = [{
          const: 'const',
          type: '正常打卡',
          cnt: this.formatNum(data.normalEndAttRate)
        }, {
          const: 'const',
          type: '未结束任务',
          cnt: this.formatNum(data.noEndAttRate)
        }];
        this.$refs.chart1.initChart();
        this.$refs.chart2.initChart();
      });
    },
    formatNum(num) {
      return parseFloat((num * 100).toFixed(2));
    },
    toggleTab() {
      this.errorType = this.errorType === 1 ? 2 : 1;
      this.$refs.chart1.initChart();
      this.$refs.chart2.initChart();
    }
  },
  computed: {
    listData() {
      return this.errorType === 1 ? this.listData1.slice(0) : this.listData2.slice(0);
    }
  }
};