import F2 from '@antv/f2/lib/index-all';
export default {
  name: 'ActiveRateChart',
  props: ['data', 'id'],
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      this.$nextTick(() => {
        let width = this.$refs.chart.offsetWidth;
        let height = this.$refs.chart.offsetHeight;
        this.chart = new F2.Chart({
          width,
          height,
          id: this.id,
          pixelRatio: window.devicePixelRatio
        });
        this.chart.source(this.data);
        this.chart.coord('polar', {
          transposed: true,
          radius: 0.9,
          innerRadius: 0.5
        });
        this.chart.axis(false);
        this.chart.legend(true);
        this.chart.tooltip(false);
        this.chart.legend({
          align: 'center',
          position: 'bottom',
          marker: {
            symbol: 'square'
          }
        });
        this.chart.interval().position('const*cnt').adjust('stack').color('type', ['#4f77aa', '#ffa45b']);
        this.chart.pieLabel({
          sidePadding: 80,
          activeShape: true,
          label1: function label1(data, color) {
            return {
              text: data.cnt + '%',
              fill: color,
              fontWeight: 'bold'
            };
          }
        });
        this.chart.render();
      });
    }
  }
};